import * as _ from 'lodash';
import {getFirebaseUser} from './firebase';

export type TAutoCompleteUser = {
  first_name: string,
  last_name: string,
  primary_name: string,
  company_name: string,
  id: string,
  type: string
}

const post = async (api: string, data: any = {}) => {
  const path = 'admin';
  const user = await getFirebaseUser();

  return await fetch(`${process.env.REACT_APP_SEARCH_API_BASE_URL}${path}/${api}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'access_token': user.token,
      },
      body: JSON.stringify({
        'api': api,
        'reqObj': {
          ...data,
          'owner_id': data.owner_id || user.uid
        }
      })
    });
};

export const verifyUser = async (): Promise<void> => {
  let resp: any;

  resp = await post('verifyUserRole', {
    'role': 'admin'
  })
  .catch((error) => {
    throw new Error('Cannot verify user role: ' + error.message);
  });

  if (!resp.ok) {
    if (resp.status === 401) {
      throw new Error('Invalid admin user');
    }
    throw new Error(resp.statusText);
  }
};

export const postOrRefresh = async (api: string, data: any = {}) => {
  let json: any, res: any;

  res = await post(api, data);
  if (!res.ok) {
    throw new Error('post failed: ' + res.statusText);
    // if (res.status === 401) {
    //   // refresh the browser to let user log in again
    //   window.location.reload();
    // } else {
    //   throw new Error('post failed: ' + res.statusText);
    // }
  }

  json = await res.json();
  return json.data;
};

export const getUserProfile = async (id: string, role: string) => {
  return await postOrRefresh('getUserProfile', {owner_id: id, role: role});
};

export const getRiderProfile = async (riderId: string) => {
  return await postOrRefresh('getRiderProfileByRiderId', {rider_id: riderId});
};

export const getRouteDetails = async (routeId: string) => {
  return await postOrRefresh('getRouteDetails', {route_id: routeId});
};

export const getZumEnterpriseDetails = async (enterpriseId: string) => {
  return await postOrRefresh('getZumEnterpriseDetails', {zum_enterprise_id: enterpriseId});
};

export const getVendorProfile = async (vendorId: string) => {
  return await postOrRefresh('getVendorProfile', {vendor_id: vendorId});
};

export const getMonthlyPackageProfile = async (monthlyPackageId: string) => {
  return await postOrRefresh('getMonthlyPackageById', {monthlyPackageId: monthlyPackageId});
};

export const getAutoCompleteUserList = async (query: string): Promise<TAutoCompleteUser[]> => {
  const data: any = await postOrRefresh('search', {
    limit: 10,
    skip: 0,
    query: query,
    types: ['customer', 'driver', 'rider', 'zum_enterprise', 'vendor']
  });
  return _.get(data, 'results.hits', [])
    .map((hit: any): TAutoCompleteUser => hit._source);
};

export const getAutoCompleteLocations = async (prefix: string): Promise<string[]> => {
  const data: any = await postOrRefresh('getApolloLocationSuggestions', {
    prefix: prefix
  });
  return _.get(data, 'options');
};
