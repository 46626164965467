import * as React from 'react';
import moment from 'moment';

export type TLinkOption = {
  name: string,
  label: string,
  value: () => string
}

type Props = {
  applyFilter: (name: string, value: string, label: string) => void,
}

const LINK_OPTS: TLinkOption[] = [
  {
    name: 'filter_now',
    label: 'Now',
    value: () => moment().format('MM/DD/YYYY_HH:mm')
  },
  {
    name: 'filter_past',
    label: 'Past',
    value: () => moment().format('MM/DD/YYYY_HH:mm')
  },
  {
    name: 'filter_upcoming',
    label: 'Upcoming',
    value: () => moment().format('MM/DD/YYYY_HH:mm')
  },
  {
    name: 'filter_start_date_range',
    label: 'Today',
    value: () => moment().format('MM/DD/YYYY')+'||/d'
      + '-' +
      moment().format('MM/DD/YYYY')+'||/d',
  },
  {
    name: 'filter_start_date_range',
    label: 'Yesterday',
    value: () => moment().subtract(1, 'days').format('MM/DD/YYYY')+'||/d'
      + '-' +
      moment().subtract(1, 'days').format('MM/DD/YYYY')+'||/d'
  },
  {
    name: 'filter_start_date_range',
    label: 'Tomorrow',
    value: () => moment().add(1, 'days').format('MM/DD/YYYY')+'||/d'
      + '-' +
      moment().add(1, 'days').format('MM/DD/YYYY')+'||/d'
  },
  {
    name: 'filter_start_date_range',
    label: 'This Week',
    value: () => moment().format('MM/DD/YYYY') + '||/w'
      + '-' +
      moment().format('MM/DD/YYYY') + '||/w'
  },
  {
    name: 'filter_start_date_range',
    label: 'Last Week',
    value: () => moment().subtract(7, 'days').format('MM/DD/YYYY')+'||/w'
      + '-' +
      moment().subtract(7, 'days').format('MM/DD/YYYY')+'||/w'
  },
  {
    name: 'filter_start_date_range',
    label: 'Next Week',
    value: () => moment().add(7, 'days').format('MM/DD/YYYY')+'||/w'
      + '-' +
      moment().add(7, 'days').format('MM/DD/YYYY')+'||/w'
  },
  {
    name: 'filter_overflow',
    label: 'Overflow',
    value: () => moment().format('MM/DD/YYYY_HH:mm')
  },
  {
    name: 'filter_start_late',
    label: 'Start Late',
    value: () => moment().format('MM/DD/YYYY_HH:mm')
  },
  {
    name: 'filter_complete_late',
    label: 'Complete Late',
    value: () => moment().format('MM/DD/YYYY_HH:mm')
  },
];

const CLASSES = [
  'btn btn-sm btn-primary', 'btn btn-sm btn-secondary', 'btn btn-sm btn-success',
  'btn btn-sm btn-primary', 'btn btn-sm btn-secondary', 'btn btn-sm btn-success',
  'btn btn-sm btn-primary', 'btn btn-sm btn-secondary', 'btn btn-sm btn-success',
  'btn btn-sm btn-danger', 'btn btn-sm btn-outline-danger', 'btn btn-sm btn-warning',
];

export const QuickLinks = ({applyFilter}: Props) => {
  return (
    <div className="form-inline mt-3">
      <div className="mr-3">Quick links:</div>
      {
        LINK_OPTS.map((link, i) =>
          <button type="button"
            key={link.label}
            className={CLASSES[i]}
            onClick={() => applyFilter(link.name, link.value(), link.label)}>
            {link.label}
          </button>
        )
      }
    </div>
  );
};
