import React from 'react';
import moment from 'moment-timezone';
import CopyToClipboard from 'react-copy-to-clipboard';

import {Format} from './Format';

type Props = {
  rideDoc: any,
}

const BusinessTypeLabel: {[value: string]: string} = {
  'b2c': 'B2C',
  'b2b': 'B2B',
  'special_b2c': 'Packaged B2C',
  'monthly_b2c': 'Monthly Package',
};

const CopyButton = ({text}: any) => (
  <CopyToClipboard text={text}>
    <button type="button" className="btn btn-outline-secondary btn-sm py-0 mr-1">c</button>
  </CopyToClipboard>
);

export const Ride = ({rideDoc}: Props) => {
  const owner: string = rideDoc.owner_name ? rideDoc.owner_name : rideDoc.owner;
  const customers: string[] = rideDoc.customer_names ? rideDoc.customer_names : rideDoc.customers;
  const riders: string[] = rideDoc.rider_names ? rideDoc.rider_names : rideDoc.riders;
  const driver: string = rideDoc.driver_name ? rideDoc.driver_name : rideDoc.driver_id;

  return (
    <div className="card mb-4">
      <div className="card-header bg-white text-secondary">
        <div className="row">
          <div className="col-md-4">
            <strong># {rideDoc.ride_display_id}</strong>
            <div className="badge badge-outline-success ml-2">{rideDoc.start_zone}</div>
            <div className="badge badge-outline-secondary ml-2">
              {BusinessTypeLabel[rideDoc.business_type]}
            </div>
            {
              rideDoc.is_carpool &&
                <div className="badge badge-outline-secondary ml-2">Carpool</div>
            }
            {
              rideDoc.is_recurrent &&
                <div className="badge badge-outline-secondary ml-2">Recurrent</div>
            }
            {
              rideDoc.issue &&
                <div className="badge badge-outline-warning ml-2">{rideDoc.issue}</div>
            }
            {
              ['BUS', 'MINI_BUS', 'YELLOW_BUS', 'SPRINTER', 'MOTOR_COACH']
                .includes(rideDoc.route_vehicle_type) &&
                <div className="badge badge-outline-primary ml-2">Bus</div>
            }
            {
              rideDoc.route_type &&
                <div className="badge badge-outline-primary ml-2">
                  <a href={`${process.env.REACT_APP_ADMIN_URL}enterprise/route-preview/${rideDoc.enterprise_id}/${rideDoc.owner}?routeId=${rideDoc.route_id}&status=ACTIVE`}
                     target='_blank' rel="noopener noreferrer"
                  >
                    {rideDoc.route_type}
                  </a>
                </div>
            }
            {
              rideDoc.carseat_required > 0 &&
                <div className="badge badge-outline-info ml-2">car-seat:{rideDoc.carseat_required}</div>
            }
            {
              rideDoc.booster_required > 0 &&
                <div className="badge badge-outline-info ml-2">booster:{rideDoc.booster_required}</div>
            }
          </div>
          <div className="col-md-4">
            <CopyButton text={rideDoc.ride_id} />
            <div className="badge badge-outline-primary">
              <a href={`${process.env.REACT_APP_ADMIN_URL}rides/list?rideId=${rideDoc.ride_id}`}
                 target='_blank' rel="noopener noreferrer">
                ID: {rideDoc.ride_id}
              </a>
            </div>
            <div className="badge badge-outline-secondary ml-2">{rideDoc.status}</div>
          </div>
          <div className="col-md-4 text-right">
            <span className="m-ride__distance mr-3">
              {(rideDoc.total_distance / 1609.34).toFixed(2)} mi
            </span>
            <span className="m-ride__time-value">
              {Math.round(rideDoc.total_duration / 60)} min
            </span>
          </div>
        </div>
      </div>
      <div className="card-body shadow-sm pt-2">
        <div className="m-ride__date mb-2 d-flex">
          <span className="flex-fill">
            {moment(rideDoc.ride_start_timestamp).format('ddd, MMM D YYYY')}
          </span>
          {
            rideDoc['@timestamp'] &&
              <span className="m-ride__event-label">
                Last updated: {new Date(rideDoc['@timestamp']).toLocaleString('en-US')}
              </span>
          }
        </div>
        <div className="row">
          <div className="m-ride__event pickup col-md-4 small">
            <div className="m-ride__event-label">First event</div>
            <div className="m-ride__event-time position-relative pl-4 mb-2">
              <span>{moment(rideDoc.ride_start_timestamp).local().format('hh:mm A')}</span>
              {
                rideDoc.timezone &&
                  <span className="ml-2">
                    (local: {moment(rideDoc.ride_start_timestamp).tz(rideDoc.timezone).format('hh:mm A')})
                  </span>
              }
            </div>
            <div className="m-ride__event-address position-relative pl-4 mb-2">
              <div className="m-ride__event-address-type font-weight-bold">Pickup</div>
              {rideDoc.start_address}
            </div>
          </div>

          <div className="m-ride__event dropoff col-md-4 small">
            <div className="m-ride__event-label">Last event</div>
            <div className="m-ride__event-time position-relative pl-4 mb-2">
              <span>{moment(rideDoc.end_time).local().format('hh:mm A')}</span>
              {
                rideDoc.timezone &&
                  <span className="ml-2">
                    (local: {moment(rideDoc.end_time).tz(rideDoc.timezone).format('hh:mm A')})
                  </span>
              }
            </div>
            <div className="m-ride__event-address position-relative pl-4 mb-2">
              <div className="m-ride__event-address-type font-weight-bold">Dropoff</div>
              {rideDoc.end_address}
            </div>
          </div>

          <div className="m-ride__event dropoff col-md-2 small">
            {
              owner &&
              <div className="m-ride__owner position-relative pl-4 mb-2 text-truncate">
                <CopyButton text={rideDoc.owner} />
                <a href={`${process.env.REACT_APP_ADMIN_URL}customers/details/${rideDoc.owner}`}
                   target='_blank' rel="noopener noreferrer"
                >
                  {owner}
                </a>
              </div>
            }
            <div className="m-ride__customer position-relative pl-4 mb-2">
            {
              customers.map((customer: string, i: number): any =>
                <div className="text-truncate" key={rideDoc.customers[i]}>
                  <CopyButton text={rideDoc.customers[i]} />
                  <a href={`${process.env.REACT_APP_ADMIN_URL}customers/details/${rideDoc.customers[i]}`}
                     target='_blank' rel="noopener noreferrer"
                  >
                    {customer}
                  </a>
                </div>
              )
            }
            </div>
            <div className="m-ride__children position-relative pl-4 mb-2">
            {
              riders.map((rider: string, i: number): any =>
                <div className="text-truncate" key={rideDoc.riders[i]}>
                  <CopyButton text={rideDoc.riders[i]} />
                  {rider}
                </div>
              )
            }
            </div>
            {
              driver &&
              <div className="m-ride__driver-info position-relative pl-4 mb-2 text-truncate">
                <CopyButton text={rideDoc.driver_id} />
                <a href={`${process.env.REACT_APP_ADMIN_URL}zumers/details/${rideDoc.driver_id}/info`}
                   target='_blank' rel="noopener noreferrer"
                >
                  {driver}
                </a>
              </div>
            }
          </div>

          <div className="col-md-2">
            <div>Customer: <Format value={rideDoc.revenue} formatter="cent_to_dollar"/></div>
            <div>Driver: <Format value={rideDoc.driver_pay} formatter="cent_to_dollar"/></div>
            <div className="small">
              Profit: <Format value={(rideDoc.revenue - rideDoc.driver_pay)} formatter="cent_to_dollar"/>
            </div>
            <div className="small">
              Margin: <Format value={((rideDoc.revenue - rideDoc.driver_pay) / rideDoc.revenue)} formatter="percentage"/>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};
