import React, {Component, RefObject} from 'react';
import ngeohash from 'ngeohash';

type Props = {
  mapInstance: any,
  mapsApi: any,
  applyFilter: (name: string, value: string, valueLabel?: string) => void,
}

export class MapSearchBox extends Component<Props, any> {
  private refBox: RefObject<HTMLDivElement> = React.createRef();
  private refInput: RefObject<HTMLInputElement> = React.createRef();

  public componentDidMount({mapInstance, mapsApi} = this.props): void {
    const autoComplete = new mapsApi.places.Autocomplete(this.refInput.current, {
      types: ['geocode'],
      fields: ['geometry']
    });
    mapInstance.controls[mapsApi.ControlPosition.TOP_LEFT].push(this.refBox.current);
    autoComplete.addListener('place_changed', () => {
      let place = autoComplete.getPlace();
      let lat: number, lng: number, gh: string;
      if (!place.geometry) {
        this.props.applyFilter('filter_text_search', place.name);
      } else {
        lat = place.geometry.location.lat();
        lng = place.geometry.location.lng();
        gh = ngeohash.encode(lat, lng);
        this.props.applyFilter('filter_geo_distance', gh);
      }
    });
  }

  public componentWillUnmount({ mapsApi } = this.props): void {
    mapsApi.event.clearInstanceListeners(this.refInput.current);
  }

  render() {
    return (
      <div className='w-50' ref={this.refBox}>
        <input type='text' className='form-control'
          ref={this.refInput} placeholder='address to search 5 miles around'
        />
      </div>
    );
  }
}
