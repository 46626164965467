import React from 'react';

export type TUnit = {
  value: string,
  label: string
}

type Props = {
  units: TUnit[],
  curUnitVal: string,
  applyUnit: (value: string, label: string) => void,
};

export const Units = ({units, curUnitVal, applyUnit}: Props) => {
  return (
    <div className="form-inline mt-3">
      <div className="mr-3">Units:</div>
      <div className="btn-group" role="group">
      {
        units.map((unit) =>
          <button type="button" key={unit.value}
            className={`btn btn-sm ${curUnitVal === unit.value ? 'btn-primary' : 'btn-outline-secondary'}`}
            onClick={() => applyUnit(unit.value, unit.label)}>
            {unit.label}
          </button>
        )
      }
      </div>
    </div>
  );
};
