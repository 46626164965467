import * as _ from 'lodash';

import {Utils} from '../utils/Utils';
import * as Zum from './zumApi';

const getNameByIdAndTypeWithCache = async (id: string, type: string): Promise<string> => {
  let prof: any, value: string|null = Utils.getItem(id);

  if (value) {
    return value;
  }

  try {
    switch (type) {
      case 'route':
        prof = await Zum.getRouteDetails(id);
        value = _.get(prof, 'name', id);
        break;
      case 'enterprise':
        prof = await Zum.getZumEnterpriseDetails(id);
        value = _.get(prof, 'profile.primary_name', id);
        break;
      case 'vendor':
        prof = await Zum.getVendorProfile(id);
        value = _.get(prof, 'vendor_info.company_name', id);
        break;
      case 'monthly_package':
        prof = await Zum.getMonthlyPackageProfile(id);
        value = _.get(prof, 'monthlyPackageName', id);
        break;
      case 'rider':
        prof = await Zum.getRiderProfile(id);
        value = (_.get(prof, 'first_name', '') + ' ' + _.get(prof, 'last_name', '')).trim() ||
          _.get(prof, 'email', id);
        break;
      case 'driver': // fall through
      case 'customer': // fall through
      case 'admin':
        prof = await Zum.getUserProfile(id, type);
        value = (_.get(prof, 'first_name', '') + ' ' + _.get(prof, 'last_name', '')).trim() ||
          _.get(prof, 'email', id);
        break;
      default:
        value = id;
        break;
    }
  } catch (e) {
    console.error(e);
    return id;
  }

  Utils.setItem(id, value!);
  return value!;
};

export const getDriverNameById = async (id: string): Promise<string> => {
  return getNameByIdAndTypeWithCache(id, 'driver');
};

export const getCustomerNameById = async (id: string): Promise<string> => {
  return getNameByIdAndTypeWithCache(id, 'customer');
};

export const getAssignerNameById = async (id: string): Promise<string> => {
  return getNameByIdAndTypeWithCache(id, 'admin');
};

export const getRiderNameById = async (id: string): Promise<string> => {
  return getNameByIdAndTypeWithCache(id, 'rider');
};

export const getRouteNameById = async (id: string): Promise<string> => {
  return getNameByIdAndTypeWithCache(id, 'route');
};

export const getEnterpriseNameById = async (id: string): Promise<string> => {
  return getNameByIdAndTypeWithCache(id, 'enterprise');
};

export const getVendorNameById = async (id: string): Promise<string> => {
  return getNameByIdAndTypeWithCache(id, 'vendor');
};

export const getMonthlyPackageNameById = async (id: string): Promise<string> => {
  return getNameByIdAndTypeWithCache(id, 'monthly_package');
};

export const getRidesWithQs = async (queryParams: any) => {
  let {
    es_time,
    total_rides,
    rides_geo_bounds,
    size,
    results,
    summary,
    aggs,
    excluded_aggs,
    ext_filters,
    units
  } = await Zum.postOrRefresh('getApolloQueryAggregation', queryParams);
  let geoBoundingPoints: google.maps.LatLngLiteral[] = [];

  if (rides_geo_bounds.value) {
    Object.keys(rides_geo_bounds.value).forEach((name: string) => {
      geoBoundingPoints = geoBoundingPoints.concat({
        lat: rides_geo_bounds.value[name].lat,
        lng: rides_geo_bounds.value[name].lon,
      });
    });
  }

  return {
    total: total_rides.value,
    size,
    es_time,
    summary,
    geoBoundingPoints,
    results,
    aggs,
    excluded_aggs,
    ext_filters,
    units
  };
};

export const populateResultsNames = async (results: any = []): Promise<any[]> => {
  const ownerNames = await Promise.all(results.map((result: any) => {
    let ownerId = result['owner'];

    if (ownerId) {
      return getCustomerNameById(ownerId)
        .catch(() => ownerId);
    } else {
      return null;
    }
  }));

  const driverNames = await Promise.all(results.map((result: any) => {
    let driverId = result['driver_id'];

    if (driverId) {
      return getDriverNameById(driverId)
        .catch(() => driverId);
    } else {
      return null;
    }
  }));
  const riderNames = await Promise.all(results.map((result: any) => {
    let riderIds = result['riders'];

    if (riderIds && riderIds.length) {
      return Promise.all(
        riderIds.map((riderId: string) => getRiderNameById(riderId))
      );
    } else {
      return [];
    }
  }));
  const customerNames = await Promise.all(results.map((result: any) => {
    let customerIds = result['customers'];

    if (customerIds && customerIds.length) {
      return Promise.all(
        customerIds.map((customerId: string) => getCustomerNameById(customerId))
      );
    } else {
      return [];
    }
  }));

  results = results.map((result: any, i: number) => ({
    ...result,
    owner_name: ownerNames[i],
    driver_name: driverNames[i],
    rider_names: riderNames[i],
    customer_names: customerNames[i]
  }));

  return results;
};

export const populateAggregationsNames = async (aggs: any = {}): Promise<any> => {
  /**
   * Process driver names
   */
  if (aggs.driver && aggs.driver.buckets) {
    let drivers: any[] = aggs.driver.buckets;
    let driverNames: string[] = await Promise.all(drivers.map((driver: any) => {
      return getDriverNameById(driver.key).catch(() => driver.key);
    }));
    aggs.driver.buckets = drivers.map((driver: any, i: number) => ({
      ...driver,
      label: driverNames[i]
    }));
  }

  /**
   * Process dropout driver names
   */
  if (aggs.dropout_driver && aggs.dropout_driver.buckets) {
    let dropoutDrivers: any[] = aggs.dropout_driver.buckets;
    let dropoutDriverNames: string[] = await Promise.all(dropoutDrivers.map((driver: any) => {
      return getDriverNameById(driver.key).catch(() => driver.key);
    }));
    aggs.dropout_driver.buckets = dropoutDrivers.map((driver: any, i: number) => ({
      ...driver,
      label: dropoutDriverNames[i]
    }));
  }

  /**
   * Process owner names
   */
  if (aggs.owner && aggs.owner.buckets) {
    let owners: any[] = aggs.owner.buckets;
    let ownerNames: string[] = await Promise.all(owners.map((owner: any) => {
      return getCustomerNameById(owner.key).catch(() => owner.key);
    }));

    aggs.owner.buckets = owners.map((customer: any, i: number) => ({
      ...customer,
      label: ownerNames[i]
    }));
  }

  /**
   * Process assigner names
   */
  if (aggs.assigned_by && aggs.assigned_by.buckets) {
    let assigners: any[] = aggs.assigned_by.buckets;
    let assignerNames: string[] = await Promise.all(assigners.map((assigner: any) => {
      return getAssignerNameById(assigner.key).catch(() => assigner.key);
    }));

    aggs.assigned_by.buckets = assigners.map((assigner: any, i: number) => ({
      ...assigner,
      label: assignerNames[i]
    }));
  }

  /**
   * Process route names
   */
  if (aggs.route && aggs.route.buckets) {
    let routes: any[] = aggs.route.buckets;
    let routeNames: string[] = await Promise.all(routes.map((route: any) => {
      return getRouteNameById(route.key).catch(() => route.key);
    }));

    aggs.route.buckets = routes.map((route: any, i: number) => ({
      ...route,
      label: routeNames[i]
    }));
  }

  /**
   * Process enterprise names
   */
  if (aggs.enterprise && aggs.enterprise.buckets) {
    let enterprises: any[] = aggs.enterprise.buckets;
    let enterpriseNames: string[] = await Promise.all(enterprises.map((e: any) => {
      return getEnterpriseNameById(e.key).catch(() => e.key);
    }));

    aggs.enterprise.buckets = enterprises.map((e: any, i: number) => ({
      ...e,
      label: enterpriseNames[i]
    }));
  }

  /**
   * Process vendor names
   */
  if (aggs.vendor && aggs.vendor.buckets) {
    let vendors: any[] = aggs.vendor.buckets;
    let vendorNames: string[] = await Promise.all(vendors.map((vendor: any) => {
      return getVendorNameById(vendor.key).catch(() => vendor.key);
    }));

    aggs.vendor.buckets = vendors.map((vendor: any, i: number) => ({
      ...vendor,
      label: vendorNames[i]
    }));
  }

  /**
   * Process monthly_package names
   */
  if (aggs.monthly_package && aggs.monthly_package.buckets) {
    let monthlyPackages: any[] = aggs.monthly_package.buckets;
    let names: string[] = await Promise.all(monthlyPackages.map((pkg: any) => {
      return getMonthlyPackageNameById(pkg.key).catch(() => pkg.key);
    }));

    aggs.monthly_package.buckets = monthlyPackages.map((pkg: any, i: number) => ({
      ...pkg,
      label: names[i]
    }));
  }

  return Object.assign({}, aggs);
};

export const startExportJob = async (params: any): Promise<string> => {
  let {export_id} = await Zum.postOrRefresh('exportApolloRides', params);
  return export_id;
};
