import React, {Component} from 'react';

import {Format} from './Format';
import {QueryCondition} from "../utils/QueryCondition";

type TProps = {
  name: string,
  title: string,
  type: string,
  formatter: string,
  isOptional: boolean,
  isBool: boolean,
  queryCondition: QueryCondition,
  data: any,
  addCondition: (name: string, value: string, valueLabel: string) => void,
  removeCondition: (name: string, value: string) => void,
  applySettingCondition: (name: string, value: string, valueLabel?: string) => void
}

export class Aggregation extends Component<TProps, any> {
  render() {
    const {name, title, type, formatter, isOptional, isBool, queryCondition, data,
      addCondition, removeCondition, applySettingCondition}: TProps = this.props;
    let content: any = null;

    switch (type) {
      case 'stats':
        content = ['count', 'min', 'max', 'avg', 'sum'].map((item) => (
          <button
            className="btn list-group-item d-flex justify-content-between align-items-center"
            key={item}
          >
            <span className="text-truncate">{item}</span>
            <span className="badge badge-light badge-pill">
            <Format formatter={item === 'count' ? 'integer' : formatter} value={data[item]}/>
          </span>
          </button>
        ));
        break;
      case 'bucket':
        content = data.buckets && data.buckets.map((bucket: any) => {
          let value: string = '';

          if (bucket.from !== undefined || bucket.to !== undefined) {
            if (bucket.from !== undefined) {
              value += 'from' + bucket.from;
            }
            if (bucket.to !== undefined) {
              value += 'to' + bucket.to;
            }
          } else {
            value = bucket.key.toString();
          }

          return (
            <div className={"list-group-item d-flex justify-content-between align-items-center"}
              key={bucket.key}
            >
              {
                isBool ||
                <input type='checkbox'
                  checked={queryCondition.existsCondition(name, value)}
                  onChange={(e: any): void => {
                    e.target.checked ?
                      addCondition(name, value, bucket.label) : removeCondition(name, value);
                    this.forceUpdate();
                  }}
                />
              }
              <button
                className={"btn btn-link text-left text-truncate flex-fill"}
                onClick={() => applySettingCondition(name, value, bucket.label)}
              >
                <span>
                  {bucket.label ? bucket.label : bucket.key}
                </span>
              </button>
              <span className="badge badge-light badge-pill ml-auto">
                <Format formatter={formatter} value={bucket.value}/>
              </span>
            </div>
          );
        });
        break;
      default:
        content = null;
        break;
    }

    return (
      <div className="card mb-3 ">
        <div className="card-header px-3 py-2 font-weight-bold">
          {
            isOptional &&
            <input type='checkbox' className={'mr-2'}
              checked={queryCondition.existsCondition('aggs', name)}
              onChange={(e: any): void => {
                e.target.checked ?
                  addCondition('aggs', name, name) : removeCondition('aggs', name);
                this.forceUpdate();
              }}
            />
          }
          {title}
        </div>
        <div className="list-group list-group-flush">
          {content}
        </div>
      </div>
    );
  }
}
