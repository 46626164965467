import React from 'react';
import * as _ from 'lodash';

export type TActiveFilter = {
 title: string,
 label: string
}

type Props = {
  activeFilters: {[name: string]: TActiveFilter},
  removeFilter: (name: string) => void
}

export const ActiveFiltersBar = ({activeFilters, removeFilter} : Props) => {
  return (
    <div className={_.isEmpty(activeFilters) ? 'd-none' : 'row mt-3'}>
      <div className="col-md-12 alert alert-primary">
      {
        Object.keys(activeFilters).map((name: string) => (
          <button className="button float-left mr-3 px-2 my-1 bg-white border rounded"
            aria-label="Close"
            key={name}
            onClick={() => removeFilter(name)}>
            {activeFilters[name].title + ': '}
            <strong>{activeFilters[name].label}</strong>
            <span aria-hidden="true" className="close ml-2">&times;</span>
          </button>
        ))
      }
      </div>
    </div>
  );
};



