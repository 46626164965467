import * as React from 'react';

import {QueryCondition} from "../utils/QueryCondition";

type TFilterOption = {
  name: string,
  title: string,
  label: string,
  value: () => string
}

type Props = {
  excludedAggsArray: any[],
  queryCondition: QueryCondition,
  applyAddingFilter: (name: string, values: string[], valueLabels?: string[]) => void,
  applyRemovingFilter: (name: string, value?: string) => void,
  applyFilter: (name: string, value: string, label?: string) => void,
}

const FILTER_OPTS: TFilterOption[] = [
  {
    name: 'filter_clean_data',
    title: 'Clean Pricing',
    label: 'true',
    value: () => '1'
  },
  {
    name: 'filter_non_canceled',
    title: 'Non-canceled',
    label: 'true',
    value: () => '1'
  },
  {
    name: 'add_all_aggs',
    title: 'Aggregate All',
    label: '',
    value: () => ''
  },
  {
    name: 'remove_all_aggs',
    title: 'Aggregate Default',
    label: '',
    value: () => ''
  }
];

export const QuickFilters = ({excludedAggsArray, queryCondition,
  applyAddingFilter, applyRemovingFilter, applyFilter}: Props) => {
  return (
    <div className="form-inline mt-3">
      <div className="mr-3">Quick filters:</div>
      <div className="btn-group" role="group">
      {
        FILTER_OPTS.map((opt) => {
          let onClick: () => void;
          if (opt.name === 'add_all_aggs') {
            onClick = () => {
              const values: string[] = excludedAggsArray.map((agg: any) => agg.aggName);
              applyAddingFilter('aggs', values);
            }
          } else if (opt.name === 'remove_all_aggs') {
            onClick = () => {
              applyRemovingFilter('aggs');
            }
          } else {
            onClick = () => {
              applyFilter(opt.name, opt.value(), opt.label);
            }
          }

          return (
            <button
              type="button" key={opt.name}
              className={'btn btn-sm ' +
              (queryCondition.getSingleConditionValue(opt.name) === opt.value() ? 'btn-primary' : 'btn-outline-secondary')}
              onClick={onClick}>
              {opt.title}
            </button>
          );
        })
      }
      </div>
    </div>
  );
};
