import React from 'react';

const SORT_OPTS = [
  {
    field: 'ride_start_timestamp',
    label: 'Start Time'
  },
  {
    field: '@timestamp',
    label: 'Last Updated'
  },
  {
    field: 'start_region',
    label: 'Region'
  },
  {
    field: 'revenue',
    label: 'Revenue'
  }
];

type Props = {
  curSortVal: string,
  applySorting: (value: string) => void;
}

export const Sorting = ({curSortVal, applySorting}: Props) => {
  let [field, order] = curSortVal.split(':');

  return (
    <div className="form-inline mt-3">
      <div className="mr-3">Sort by:</div>
      <div className="btn-group" role="group">
      {
        SORT_OPTS.map((option) => {
          const active: boolean = (field === option.field);
          return (
            <button type="button"
              key={option.field}
              className={'btn btn-sm ' + (active ? 'btn-primary' : 'btn-outline-secondary')}
              onClick={() => {
                if (active) {
                  order = (order === 'asc' ? 'desc' : 'asc');
                } else {
                  field = option.field;
                  order = 'desc';
                }
                applySorting(field + ':' + order);
              }}>
              {option.label}
              {active ? (order === 'desc' ? <> &darr;</> : <> &uarr;</>) : ''}
            </button>
          );
        })
      }
      </div>
    </div>
  );
};
