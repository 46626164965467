import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';

let currentUser: any = null;
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  storageBucket: `gs://${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}`
};

firebase.initializeApp(firebaseConfig);

export const signInFirebase = async (email: string, password: string): Promise<void> => {
  // clear previously cached user
  currentUser = null;
  await firebase.auth().signInWithEmailAndPassword(email, password)
    .catch((error) => {
      throw new Error(error.code + ': ' + error.message);
    });
};

export const signOutFirebase = async (): Promise<void> => {
  return await firebase.auth().signOut();
};

export const getFirebaseUser = async (): Promise<any> => {
  if (!currentUser) {
    currentUser = await new Promise((resolve, reject) => {
      firebase.auth().onAuthStateChanged((authUser) => {
        if (authUser) {
          //signed in or in signed-in state
          resolve(authUser);
        } else {
          //signed out or in signed-out state
          reject(new Error('User not signed in'));
        }
      });
    });
  }

  // Get the latest/refreshed Id token every time
  currentUser.token = await currentUser.getIdToken(true);

  return currentUser;
};

export const getDownloadUrl = async (exportId: string): Promise<string|null> => {
  const exist = await firebase.storage().ref(`exports/${exportId}`).getMetadata().catch(() => null);
  return exist &&
    `https://storage.cloud.google.com/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/exports/${exportId}`;
};

export {firebase};
