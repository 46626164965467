import React, {Fragment} from 'react';
import _ from 'lodash';

import * as Service from '../services/service';
import * as Firebase from '../services/firebase';
import * as ZumApi from '../services/zumApi';
import {TConditionObject, TCondition} from "../utils/QueryCondition";
import {BaseComponent, TState} from "./BaseComponent";

import {Aggregation} from '../components/Aggregation';
import {Paginator} from '../components/Paginator';
import {Ride} from '../components/Ride';
import {Format} from '../components/Format';
import {ActiveFiltersBar, TActiveFilter} from '../components/ActiveFiltersBar';
import {CommonWidgets} from "./CommonWidgets";
import {ExportModal} from "./ExportModal";

export class Search extends BaseComponent {
  state: TState = {
    // data states
    total: 0,
    size: 30,
    es_time: {},
    summary: [],
    results: [],
    aggs: {},
    ext_filters: {},
    units: {},

    // UI states
    conditionObj: this.queryCondition.getConditionObject(),
    conditionChanged: false,
    loading: false,
    error: '',

    //data export
    exportAuthorized: false,
    exporting: false,

    //text search
    searchText: '',
    showSuggestions: false,
    userSuggestions: [],
    locationSuggestions: []
  };

  protected generateRemovingConditionNames = (): string[] => {
    return ['size', 'from'];
  };

  protected generateDefaultConditions = (): TConditionObject => {
    return {
      sort: [{
        value: 'ride_start_timestamp:desc',
        label: 'N/A'
      }]
    };
  };

  protected fetchData = async (): Promise<void> => {
    this.setState(() => ({loading: true}));
    try {
      const {
        total,
        size,
        es_time,
        summary,
        results,
        aggs,
        excluded_aggs,
        ext_filters,
        units
      } = await Service.getRidesWithQs(this.queryCondition.toQueryParams());
      const user = await Firebase.getFirebaseUser();
      const exportAuthorized = [
        'abhishek+admin@liftee.com',
        'xwang+admin@ridezum.com',
        'andrewm+admin@ridezum.com',
        'sreejith+admin@ridezum.com',
        'sxu@ridezum.com'
      ].includes(user.email);

      this.setState({
        total,
        size,
        es_time,
        summary,
        results,
        aggs,
        excluded_aggs,
        ext_filters,
        units,
        exportAuthorized,
        error: ''
      }, this.postFetchData);
    } catch (e) {
      console.log(e);
      this.setError(e.name+': '+e.message);
    } finally {
      this.setState({loading: false});
    }
  };

  protected onSuggestionsFetchRequested = async (value: string): Promise<void> => {
    let users: ZumApi.TAutoCompleteUser[] = [], locations: string[] = [];
    if (value) {
      [users, locations] = await Promise.all([
        ZumApi.getAutoCompleteUserList(value),
        ZumApi.getAutoCompleteLocations(value)
      ]);
    }
    this.setState({
      showSuggestions: users.length > 0 || locations.length > 0,
      userSuggestions: users,
      locationSuggestions: locations
    });
  };

  render(): any {
    const {
      // data states
      total,
      size,
      es_time,
      summary,
      results,
      aggs,
      excluded_aggs,
      ext_filters,
      units,

      // UI states
      conditionObj,
      conditionChanged,
      exporting,

      // text search
      searchText,
      showSuggestions,
      userSuggestions,
      locationSuggestions
    } = this.state;
    const activeFilters: {[name: string]: TActiveFilter} = {};
    let aggsArray: any[], excludedAggsArray: any[];
    let suggestionList: any = null;

    if (showSuggestions) {
      suggestionList = (
        <ul className="autocomplete_suggestions">
          {
            userSuggestions!.map((item: ZumApi.TAutoCompleteUser, i: number) => {
              let name: string;
              switch (item.type) {
                case 'customer':
                case 'rider':
                case 'driver':
                  name = `${item.first_name} ${item.last_name} [${item.type}]`;
                  break;
                case 'vendor':
                  name = `${item.company_name || item.primary_name} [${item.type}]`;
                  break;
                case 'zum_enterprise':
                  name = `${item.primary_name} [${item.type}]`;
                  break;
                default:
                  name = `unknown type -> [${item.type}]`;
                  break;
              }

              return (
                <li key={'user'+i} onMouseDown={() => {
                  this.setState({
                    searchText: item.id || 'unregistered',
                    showSuggestions: false
                  })
                }}>
                  {name}
                </li>
              );
            })
          }
          {
            locationSuggestions!.map((l: string) => {
              return (
                <li key={l} onMouseDown={() => {
                  this.setState({
                    searchText: l,
                    showSuggestions: false
                  })
                }}>
                  {l}
                </li>
              );
            })
          }
        </ul>
      );
    }

    // process Active filters for display
    Object.keys(conditionObj!)
      .filter((name: string) => !['size', 'from', 'sort', 'unit', 'aggs'].includes(name))
      .forEach((name: any) => {
        let title: string;

        if (aggs[name]) {
          title = aggs[name].config.title;
        } else if (ext_filters[name]) {
          title = ext_filters[name].title;
        } else {
          title = name;
        }
        activeFilters[name] = {
          title: title,
          label: conditionObj![name].map((cond: TCondition) => cond.label).join(', ')
        };
      });

    // transform aggregations to array sorted by order
    [aggsArray, excludedAggsArray] = [aggs, excluded_aggs].map((aggObj: any) => {
      return Object.keys(aggObj || {}).map((aggName: any) => ({
        aggName,
        ...aggObj[aggName]
      }));
    });
    aggsArray = _.orderBy(aggsArray, 'config.order');
    excludedAggsArray = _.orderBy(excludedAggsArray, 'config.order');

    return <div className="App container-fluid pt-3">
      {/* Search bar */}
      <div className="row mb-3">
        <div className="col-6 offset-3">
          <div className="input-group mb-1">
            <input type="text" className="form-control"
              placeholder="search" aria-label="search"
              value={this.state.searchText}
              onChange={(e) => {
                this.setState({searchText: e.currentTarget.value});
                this.onSuggestionsFetchRequested(e.currentTarget.value);
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  this.applySettingExclusiveCondition('filter_text_search', searchText!);
                }
              }}
              onFocus={() => this.setState({
                showSuggestions: userSuggestions!.length > 0 || locationSuggestions!.length > 0
              })}
              onBlur={() => this.setState({showSuggestions: false})}
            />
            <div className="input-group-append">
              <button className="btn btn-primary"
                onClick={() => {
                  this.applySettingExclusiveCondition('filter_text_search', searchText!);
                }}>
                Search
              </button>
              <button className="btn btn-success"
                disabled={!total || total < 0 || total > 200*1000 || !this.state.exportAuthorized}
                onClick={() => { this.setState({exporting: true}); }}>
                Export
              </button>
            </div>
          </div>
          {suggestionList}
        </div>
      </div>

      {/* Active filters bar */}
      <ActiveFiltersBar
        activeFilters={activeFilters}
        removeFilter={this.applyRemovingCondition}
      />

      <div className="row">
        {/* Summary & Aggregations */}
        <div className="col-md-3">
          {/* summary */}
          <div className="card mb-3">
            <div className="card-header">
              <dl className="row my-0">
              {
                summary!.map((item: any) => (
                  <Fragment key={item.title}>
                    <dt className="col-md-6">{item.title + ': '}</dt>
                    <dd className="text-right col-6 mb-0">
                      <Format value={item.value} formatter={item.formatter}/>
                    </dd>
                  </Fragment>
                ))
              }
              </dl>
            </div>
          </div>
          {
            /* aggregations */
            [aggsArray, excludedAggsArray].map((aa: any[]) => {
              return aa.map((agg: any) => {
                // hide aggregation on boolean field whose filter is already applied
                if (activeFilters[agg.aggName] && agg.config.bool)
                  return null;
                if (agg.config.hidden)
                  return null;
                return (
                  <Aggregation
                    key={agg.aggName}
                    name={agg.aggName}
                    title={agg.config.title}
                    type={agg.config.aggType}
                    formatter={agg.config.formatter}
                    isOptional={agg.config.optional}
                    isBool={agg.config.bool}
                    queryCondition={this.queryCondition}
                    data={agg}
                    addCondition={this.addCondition}
                    removeCondition={this.removeCondition}
                    applySettingCondition={this.applySettingCondition}
                  />
                );
              });
            })
          }
        </div>

        <div className="col-md-9">
          {/* CommonWidgets/sorting/rides */}
          <CommonWidgets
            units={units}
            excludedAggsArray={excludedAggsArray}
            queryCondition={this.queryCondition}
            applyAddingFilter={this.applyAddingConditions}
            applyRemovingFilter={this.applyRemovingCondition}
            applyFilter={this.applySettingCondition}
            applyExclusiveFilter={this.applySettingExclusiveCondition}
          />

          {/* rides results */}
          {
            this.state.loading ?
              <div className="spinner-border m-1" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              :
              <div className="my-2">
                {es_time.title + ': '}
                <Format value={es_time.value} formatter={es_time.formatter}/>
              </div>
          }

          {
            this.state.error &&
            <div className="alert alert-danger">
              {this.state.error}
            </div>
          }

          {
            <Fragment>
            {
              (!results || !results.length) ? null :
              <Fragment>
                <Fragment>
                  <Paginator
                    total={total!}
                    pageSize={size!}
                    from={this.queryCondition.getSingleConditionValue('from') || '0'}
                    setFrom={(from: string): void => this.applySettingCondition('from', from)}
                  />
                </Fragment>
                <Fragment>
                {
                  results.map((result: any) =>
                    <Ride
                      key={result.ride_id}
                      rideDoc={result}
                    />
                  )
                }
                </Fragment>
              </Fragment>
            }
            </Fragment>
          }

          <Paginator
            total={total!}
            pageSize={size!}
            from={this.queryCondition.getSingleConditionValue('from') || '0'}
            setFrom={(from: string): void => this.applySettingCondition('from', from)}
          />
        </div>
      </div>
      {
        conditionChanged &&
        <input type='button' className={'fixed-bottom btn-primary btn-block'} value={'Apply'}
          onClick={this.search} />
      }
      {
        exporting &&
          <ExportModal
            params={this.queryCondition.toQueryParams()}
            close={() => this.setState({exporting: false})}
          />
      }
    </div>;
  }
}
