import React, {Component} from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

type Props = {
  curFilterVal: string,
  applyDateFilter: (value: string) => void
};

class DateInput extends Component<{value?: any; onClick?: any; defaultLabel: string}> {
  render() {
    return (
      <button
        className={`btn btn-sm mr-2 ${this.props.value ? `btn-primary` : `btn-outline-secondary`}`}
        onClick={this.props.onClick}>
        {this.props.value ? this.props.value : (this.props.defaultLabel ? this.props.defaultLabel : 'Select Date')}
      </button>
    )
  }
}

export const DateFilter = ({curFilterVal, applyDateFilter}: Props) => {
  let from: string, to: string, fromDate: Date|null = null, toDate: Date|null = null;

  if (curFilterVal && /^\d\d\/\d\d\/\d\d\d\d-\d\d\/\d\d\/\d\d\d\d$/.test(curFilterVal)) {
    [from, to] = curFilterVal.split('-');
    fromDate = moment(from, 'MM/DD/YYYY').toDate();
    toDate = moment(to, 'MM/DD/YYYY').toDate();
  }

  return <div className="form-inline mt-3">
    <div className="mr-3">Date:</div>
    <DatePicker
      customInput={<DateInput defaultLabel="from"/>}
      className="form-control form-control-sm mr-3"
      selected={fromDate}
      onChange={(start: Date) => {
        let end: Date = (toDate && toDate >= start) ? toDate : start;
        return applyDateFilter(
          moment(start).format('MM/DD/YYYY')+'-'+moment(end).format('MM/DD/YYYY')
        );
      }}
    />
    <DatePicker
      customInput={<DateInput defaultLabel="to"/>}
      className="form-control form-control-sm"
      selected={toDate}
      onChange={(end: Date) => {
        let start: Date = (fromDate && fromDate <= end) ? fromDate : end;
        return applyDateFilter(
          moment(start).format('MM/DD/YYYY')+'-'+moment(end).format('MM/DD/YYYY')
        );
      }}
    />
  </div>
};
