import React from 'react';

type Props = {
  total: number;
  pageSize: number;
  from: string,
  setFrom: (from: string) => void;
}

export const Paginator = ({total, pageSize, from, setFrom}: Props) => {
  const gotoPage = (pageIdx: number) => setFrom(((pageIdx-1)*pageSize).toString());
  const intFrom: number = !isNaN(parseInt(from)) ? parseInt(from) : 0;
  const pageCnt: number = Math.ceil(total / pageSize);
  const curFrom: number = Math.floor(intFrom / pageSize) * pageSize;
  const pageIdx: number = Math.floor(curFrom / pageSize) + 1;

  return (
    <nav aria-label="Page navigation">
      <ul className="pagination">
        <li className="page-item">
          <button className="page-link"
            onClick={() => {
              if (pageIdx > 1) {
                gotoPage(pageIdx - 1);
              }
            }}
          >
            &lt;
          </button>
        </li>
        <div className="input-group">
          <div className="input-group-text">{pageIdx}</div>
          <div className="input-group-prepend">
            <div className="input-group-text">{'/ ' + pageCnt}</div>
          </div>
        </div>
        <li className="page-item">
          <button className="page-link"
            onClick={() => {
              if (pageIdx < pageCnt) {
                gotoPage(pageIdx + 1);
              }
            }}
          >
            &gt;
          </button>
        </li>
      </ul>
    </nav>
  );
}
