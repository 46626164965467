import * as React from 'react';
import {PieChart, Pie, Cell, BarChart, Bar, XAxis, ResponsiveContainer} from 'recharts';
import * as _ from 'lodash';

import {Aggregation} from '../components/Aggregation';
import {ActiveFiltersBar, TActiveFilter} from '../components/ActiveFiltersBar';
import {Format} from "../components/Format";
import {CommonWidgets} from "./CommonWidgets";

import * as Service from '../services/service';
import {TCondition, TConditionObject} from "../utils/QueryCondition";
import {BaseComponent, TState} from "./BaseComponent";

export class Metrics extends BaseComponent {
  state: TState = {
    // data states
    es_time: {},
    aggs: {},
    excluded_aggs: {},
    ext_filters: {},
    units: {},

    // UI states
    conditionChanged: false,
    conditionObj: this.queryCondition.getConditionObject(),
    loading: false,
    error: '',
  };

  protected generateRemovingConditionNames = (): string[] => {
    return ['sort', 'from'];
  };

  protected generateDefaultConditions = (): TConditionObject => {
    return {
      size: [{
        value: '0',
        label: 'N/A'
      }]
    };
  };

  protected fetchData = async(): Promise<void> => {
    this.setState(() => ({loading: true}));
    try {
      const {
        es_time,
        aggs,
        excluded_aggs,
        ext_filters,
        units
      } = await Service.getRidesWithQs(this.queryCondition.toQueryParams());

      this.setState({
        es_time,
        aggs,
        excluded_aggs,
        ext_filters,
        units,
        error: ''
      }, this.postFetchData);
    } catch (e) {
      console.log(e);
      this.setError(e.name+': '+e.message);
    } finally {
      this.setState({loading: false});
    }
  };

  private renderCustomizedLabel: (props: any) => any = (props: any) => {
    const {payload} = props;
    return <div>{payload.name}</div>;
  };

  public render() {
    const COLORS = ['#FFBB28', '#FF8042', '#0088FE', '#00C49F'];
    const {
      es_time,
      aggs,
      excluded_aggs,
      ext_filters,
      units,
      conditionChanged,
      conditionObj,
      error
    } = this.state;
    const activeFilters: {[name: string]: TActiveFilter} = {};
    let aggsArray: any[], excludedAggsArray: any[];

    // process Active filters for display
    Object.keys(conditionObj!)
      .filter((name: string) => !['size', 'from', 'sort', 'unit', 'aggs'].includes(name))
      .forEach((name: any) => {
        let title: string;

        if (aggs[name]) {
          title = aggs[name].config.title;
        } else if (ext_filters[name]) {
          title = ext_filters[name].title;
        } else {
          title = name;
        }
        activeFilters[name] = {
          title: title,
          label: conditionObj![name].map((cond: TCondition) => cond.label).join(', ')
        };
      });

    // transform aggregations to array sorted by order
    [aggsArray, excludedAggsArray] = [aggs, excluded_aggs].map((aggObj: any) => {
      return Object.keys(aggObj || {}).map((aggName: any) => ({
        aggName,
        ...aggObj[aggName]
      }));
    });
    aggsArray = _.orderBy(aggsArray, 'config.order');
    excludedAggsArray = _.orderBy(excludedAggsArray, 'config.order');

    return (
      <div className="App container">
        <header className="App-header mt-5">
          <h2>Zum Metrics</h2>
        </header>

        {/* Active filters bar */}
        <ActiveFiltersBar
          activeFilters={activeFilters}
          removeFilter={this.applyRemovingCondition}
        />

        {/* CommonWidgets/sorting/rides */}
        <CommonWidgets
          units={units}
          excludedAggsArray={excludedAggsArray}
          queryCondition={this.queryCondition}
          applyAddingFilter={this.applyAddingConditions}
          applyRemovingFilter={this.applyRemovingCondition}
          applyFilter={this.applySettingCondition}
          applyExclusiveFilter={this.applySettingExclusiveCondition}
        />

        {
          this.state.loading ?
          <div className="spinner-border m-1" role="status">
            <span className="sr-only">Loading...</span>
          </div>
            :
          <div className="my-2">
            {es_time.title + ': '}
            <Format value={es_time.value} formatter={es_time.formatter}/>
          </div>
        }

        {
          error &&
          <div className="alert alert-danger">
            {error}
          </div>
        }

        <div className="row">
        {
          [aggsArray, excludedAggsArray].map((aa: any[]) => {
            return aa.map((agg: any) => {
              let chartData: any[];

              if (activeFilters[agg.aggName] && agg.config.bool)
                return null;
              if (agg.config.hidden)
                return null;
              if (agg.config.aggType !== 'bucket')
                return null;

              chartData = agg.buckets && agg.buckets.map((bucket: any) => ({
                name: bucket.label,
                value: bucket.value
              }));

              return (
                <div className="col-md-3" key={agg.aggName}>
                  <Aggregation
                    key={agg.aggName}
                    name={agg.aggName}
                    title={agg.config.title}
                    type={agg.config.aggType}
                    formatter={agg.config.formatter}
                    isOptional={agg.config.optional}
                    isBool={agg.config.bool}
                    queryCondition={this.queryCondition}
                    data={agg}
                    addCondition={this.addCondition}
                    removeCondition={this.removeCondition}
                    applySettingCondition={this.applySettingCondition}
                  />

                  {
                    !chartData ? null :
                      (agg.config.chartType === 'pie' && chartData.every((e: any) => e.value >= 0)) ?
                        <ResponsiveContainer width={'100%'} height={200}>
                          <PieChart>
                            <Pie
                              data={chartData}
                              dataKey="value"
                              cx={100}
                              cy={100}
                              fill="#8884d8"
                              isAnimationActive={false}
                              labelLine={false}
                              label={this.renderCustomizedLabel}
                            >
                              {
                                chartData.map((entry: any, index: number) => (
                                  <Cell key={index} fill={COLORS[index % COLORS.length]}/>
                                ))
                              }
                            </Pie>
                          </PieChart>
                        </ResponsiveContainer>
                        :
                        <ResponsiveContainer width={'100%'} height={200}>
                          <BarChart width={150} height={100} data={chartData}>
                            <XAxis dataKey="name"/>
                            <Bar
                              dataKey='value'
                              fill='#8884d8'
                            />
                          </BarChart>
                        </ResponsiveContainer>
                  }
                </div>
              );
            });
          })
        }
        </div>
        {
          conditionChanged &&
          <input type='button' className={'fixed-bottom btn-primary btn-block'} value={'Apply'}
            onClick={this.search} />
        }
      </div>
    );
  }
}
