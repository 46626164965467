import * as _ from 'lodash';
import * as Service from '../services/service';

export type TCondition = {
  value: string,
  label: string
}

export type TConditionObject = {
  [name: string]: TCondition[]
}

export class QueryCondition {
  private readonly json: TConditionObject = {};

  constructor(qs?: string) {
    if (!qs)
      return;

    qs.split('&').forEach((part) => {
      let [name, wholeValue] = part.split('=');

      if (!name || !wholeValue)
        return;

      try {
        wholeValue = decodeURIComponent(wholeValue);
      } catch (e) {}

      this.json[name] = wholeValue.split(',').map((value: string) => ({
        value: value,
        label: value
      }));
    });
  }

  public toQueryString(): string {
    let qsNew: string[] = Object.keys(this.json).map((name) => {
      const values: string[] = this.json[name].map((item: TCondition) => item.value);
      return name+'='+values.join(',');
    });
    return qsNew.join('&');
  }

  public toQueryParams(): any {
    const params: any = {};
    Object.keys(this.json).forEach((name) => {
      const values: string[] = this.json[name].map((item: TCondition) => item.value);
      params[name] = values.join(',');
    });
    return params;
  }

  public setCondition(name: string, value: string, label?: string): QueryCondition {
    delete this.json[name];
    this.addCondition(name, value, label);
    return this;
  }

  public addCondition(name: string, value: string, label?: string): QueryCondition {
    if (!this.json[name]) {
      this.json[name] = [];
    }
    if (!this.json[name].some((cond: TCondition) => cond.value === value)) {
      this.json[name].push({
        value: value,
        label: label || value
      });
    }
    return this;
  }

  public removeCondition(name: string, value?: string): QueryCondition {
    if (value === undefined) {
      delete this.json[name];
    } else {
      _.remove(this.json[name], (cond: TCondition) => cond.value === value);
      if (this.json[name] && this.json[name].length === 0) {
        delete this.json[name];
      }
    }
    return this;
  }

  public getConditionNames(): string[] {
    return Object.keys(this.json);
  }

  public getConditionObject(): TConditionObject {
    return _.cloneDeep(this.json);
  }

  public getConditions(name: string): TCondition[] | undefined {
    return _.cloneDeep(this.json[name]);
  }

  public existsCondition(name: string, value: string): boolean {
    const conds: TCondition[] | undefined = this.getConditions(name);
    return !!conds && conds.some((cond: TCondition) => cond.value === value);
  }

  public getSingleCondition(name: string): TCondition | undefined {
    const conds: TCondition[] | undefined = this.getConditions(name);
    if (!conds)
      return undefined;
    return conds[0];
  }

  public getSingleConditionValue(name: string): string | undefined {
    const cond = this.getSingleCondition(name);
    return cond && cond.value;
  }

  public getConditionLabels(name: string): string[] {
    return (this.json[name] || []).map((cond: TCondition) => cond.label);
  }

  public async populateUserNameFromUserId() {
    for (let name of Object.keys(this.json)) {
      for (let cond of this.json[name]) {
        if (name === 'driver' || name === 'dropout_driver') {
          cond.label = await Service.getDriverNameById(cond.value);
        } else if (name === 'owner') {
          cond.label = await Service.getCustomerNameById(cond.value);
        }
      }
    }
  }
}
