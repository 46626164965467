import {Component} from 'react';
import * as _ from 'lodash';

import * as Service from '../services/service';
import * as ZumApi from '../services/zumApi';
import {QueryCondition, TCondition, TConditionObject} from "../utils/QueryCondition";

export type TState = {
  // data states
  total?: number,
  size?: number,
  es_time?: any,
  geoBoundingPoints?: google.maps.LatLngLiteral[],
  summary?: any[],
  results?: any[],
  aggs?: any,
  excluded_aggs?: any,
  ext_filters?: any,
  units?: any,

  // UI states
  conditionObj?: TConditionObject,
  conditionChanged?: boolean,
  loading?: boolean,
  error?: string,

  //data export
  exportAuthorized?: boolean,
  exporting?: boolean,

  //test search
  searchText?: string,
  showSuggestions?: boolean,
  userSuggestions?: ZumApi.TAutoCompleteUser[],
  locationSuggestions?: string[]
}

type TProps = {
  history: any,
}

export abstract class BaseComponent extends Component<TProps, TState> {
  protected queryCondition: QueryCondition = new QueryCondition();
  protected abstract async fetchData(): Promise<void>;

  protected setError = (errMsg: string): void => {
    this.setState({error: errMsg});
  };

  protected generateDefaultConditions = (): TConditionObject => {
    return {};
  };

  protected generateRemovingConditionNames = (): string[] => {
    return [];
  };

  private cleanQueryConditionObject = (): void => {
    const removingNames: string[] = this.generateRemovingConditionNames();
    const defaultObj: TConditionObject = this.generateDefaultConditions();

    removingNames.forEach((name: string) => {
      this.queryCondition.removeCondition(name);
    });
    Object.keys(defaultObj).forEach((name: string) => {
      this.queryCondition.setCondition(name, _.get(defaultObj, [name, '0', 'value']));
    });
  };

  protected postFetchData = async (): Promise<void> => {
    const results: any = await Service.populateResultsNames(this.state.results);
    const aggs: any = await Service.populateAggregationsNames(this.state.aggs);
    await this.queryCondition.populateUserNameFromUserId();
    this.setState({results, aggs});
  };

  protected search = (): void => {
    this.props.history.replace('?' + this.queryCondition.toQueryString());
    this.setState({
      conditionChanged: false,
      conditionObj: this.queryCondition.getConditionObject(),
    });
    this.fetchData();
  };

  public applySettingExclusiveCondition = (name: string, value: string, valueLabel?: string): void => {
    //keep aggs conditions
    const aggsConditions: TCondition[] = this.queryCondition.getConditions('aggs') || [];

    this.queryCondition = new QueryCondition();
    this.cleanQueryConditionObject();

    aggsConditions.forEach((cond: TCondition) => {
      this.queryCondition.addCondition('aggs', cond.value, cond.label);
    });

    this.queryCondition.setCondition(name, value, valueLabel);
    this.search();
  };

  public applySettingCondition = (name: string, value: string, valueLabel?: string): void => {
    // clear 'from' before executing the new query
    if (name !== 'from') {
      this.queryCondition.removeCondition('from');
    }
    this.queryCondition.setCondition(name, value, valueLabel);
    this.search();
  };

  public applyAddingConditions = (name: string, values: string[], valueLabels?: string[]): void => {
    values.forEach((value: string, i: number) => {
      this.addCondition(name, value, valueLabels && valueLabels[i]);
    });
    this.search();
  };

  public applyRemovingCondition = (name: string, value?: string): void => {
    this.removeCondition(name, value);
    this.search();
  };

  public addCondition = (name: string, value: string, valueLabel?: string): void => {
    // clear 'from' before executing the new query
    if (name !== 'from') {
      this.queryCondition.removeCondition('from');
    }
    this.queryCondition.addCondition(name, value, valueLabel);
    this.setState({conditionChanged: true});
  };

  public removeCondition = (name: string, value?: string): void => {
    // clear 'from' before executing the new query
    if (name !== 'from') {
      this.queryCondition.removeCondition('from');
    }
    this.queryCondition.removeCondition(name, value);
    this.setState({conditionChanged: true});
  };

  public componentDidMount(): void {
    //normalize search string in case it is manually input
    this.queryCondition = new QueryCondition(window.location.search.slice(1));
    this.cleanQueryConditionObject();
    this.search();
  }
}
