export class Utils {
  private static ls: any = window.localStorage;

  public static sleep(ms: number): Promise<boolean> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, ms)
    });
  }

  public static setItem(key: string, value: string): void {
    try {
      Utils.ls.setItem(key, value);
    } catch (e) {
      // clear the cache when it's full (of historical caches, 5Mb)
      Utils.ls.clear();
    }
  }

  public static getItem(key: string): string|null {
    return Utils.ls.getItem(key)
  }
}
