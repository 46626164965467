import React, {Fragment} from 'react';
import _ from 'lodash';

import {QuickLinks} from "../components/QuickLinks";
import {DateFilter} from "../components/DateFilter";
import {TUnit, Units} from "../components/Units";
import {QuickFilters} from "../components/QuickFilters";
import {QueryCondition} from "../utils/QueryCondition";
import {Sorting} from "../components/Sorting";

type Props = {
  units: any,
  excludedAggsArray: any[],
  queryCondition: QueryCondition,
  applyAddingFilter: (name: string, values: string[], valueLabels?: string[]) => void,
  applyRemovingFilter: (name: string, value?: string) => void,
  applyFilter: (name: string, value: string, valueLabel?: string) => void,
  applyExclusiveFilter: (name: string, value: string, valueLabel?: string) => void
}

export const CommonWidgets = ({units, excludedAggsArray, queryCondition,
  applyAddingFilter, applyRemovingFilter, applyFilter, applyExclusiveFilter} : Props) => {
  let unitsArray: TUnit[];

  // transform units to array sorted by order
  unitsArray = Object.keys(units).map((unitName: any) => ({
    value: unitName,
    ...units[unitName]
  }));
  unitsArray = [{value: '', label: 'Count'}].concat(_.orderBy(unitsArray, 'order'));

  return (
    <Fragment>
      <QuickLinks applyFilter={applyExclusiveFilter} />

      <QuickFilters
        excludedAggsArray={excludedAggsArray}
        queryCondition={queryCondition}
        applyAddingFilter={applyAddingFilter}
        applyRemovingFilter={applyRemovingFilter}
        applyFilter={applyFilter} />

      <DateFilter
        curFilterVal={queryCondition.getSingleConditionValue('filter_start_date_range') || ''}
        applyDateFilter={(value: string): void => {
          applyFilter('filter_start_date_range', value)
        }}
      />

      <Units
        units={unitsArray}
        curUnitVal={queryCondition.getSingleConditionValue('unit') || ''}
        applyUnit={(value: string, label:string) => applyFilter('unit', value, label)}
      />

      <Sorting
        curSortVal={queryCondition.getSingleConditionValue('sort') || ''}
        applySorting={(value: string): void => applyFilter('sort', value)}
      />
    </Fragment>
  );
};
