import React, {Component, Fragment} from 'react';
import GoogleMapReact from 'google-map-react';
import ngeohash from 'ngeohash';

import {Format} from "./Format";
import {MapSearchBox} from "./MapSearchBox";

export type TMapPoint = {
  key: string,
  lat: number,
  lng: number,
  title: string,
  label: string,
  value: number,
  formatter: string,
  className?: string
}

type Props = {
  points: TMapPoint[],
  boundingPoints: google.maps.LatLngLiteral[],
  rides: any[],
  onClickPoint: (value: string, valueLabel: string) => void,
  applyFilter: (name: string, value: string, valueLabel?: string) => void,
}

type State = {
  mapApiLoaded: boolean,
  mapInstance: any,
  mapsApi: any
}

export class Map extends Component<Props, State> {
  state: State = {
    mapApiLoaded: false,
    mapInstance: undefined,
    mapsApi: undefined
  };

  private getMapBounds = (maps: any,
    bPoints: google.maps.LatLngLiteral[], points: TMapPoint[], rides: any[]): google.maps.LatLngBounds => {
    let bounds: google.maps.LatLngBounds;
    const swUSA = ngeohash.decode('9hr'), neUSA = ngeohash.decode('f89');

    if (bPoints.length === 0 && points.length === 0) {
      return new maps.LatLngBounds(
        {lat: swUSA.latitude, lng: swUSA.longitude},
        {lat: neUSA.latitude, lng: neUSA.longitude});
    }

    bounds = new maps.LatLngBounds();

    bPoints.concat(points).forEach((p) => {
      bounds.extend(new maps.LatLng(
        p.lat,
        p.lng,
      ));
    });

    // add end-points of the rides to be showed into the map bounds
    rides.forEach((r) => {
      bounds.extend(new maps.LatLng(
        r.end_geo.lat,
        r.end_geo.lon,
      ));
    });

    return bounds;
  };

  private apiIsLoaded = (map: any, maps: any, bPoints: google.maps.LatLngLiteral[], points: TMapPoint[], rides: any[]) => {
    const bounds: google.maps.LatLngBounds = this.getMapBounds(maps, bPoints, points, rides);

    // Fit map to its bounds after the api is loaded
    map.fitBounds(bounds);

    // Bind the resize listener:  re-center map when resizing the window
    maps.event.addDomListenerOnce(map, 'idle', () => {
      maps.event.addDomListener(window, 'resize', () => {
        map.fitBounds(bounds);
      });
    });

    this.setState({
      mapApiLoaded: true,
      mapInstance: map,
      mapsApi: maps
    });
  };

  public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
    let bounds: google.maps.LatLngBounds;
    const {boundingPoints, points, rides} = this.props;

    if (!this.state.mapApiLoaded)
      return;

    bounds = this.getMapBounds(this.state.mapsApi, boundingPoints, points, rides);
    this.state.mapInstance.fitBounds(bounds);
  }

  render() {
    const {
      boundingPoints,
      points,
      rides,
      onClickPoint,
      applyFilter
    }: Props = this.props;
    const {
      mapApiLoaded,
      mapInstance,
      mapsApi
    } : State = this.state;

    // Important! Always set the container height explicitly
    return <Fragment>
      {
        mapApiLoaded &&
        <MapSearchBox mapInstance={mapInstance} mapsApi={mapsApi} applyFilter={applyFilter} />
      }
      <div style={{height: '50vh', width: '100%'}}>
        <GoogleMapReact
          options={{
            gestureHandling: 'greedy',
          }}
          bootstrapURLKeys={{key: 'AIzaSyA2qDKY-VRvNI8wLF3N7Blb16_YF-lHqqQ'}}
          yesIWantToUseGoogleMapApiInternals
          center={{lat: 0, lng: 0}}
          zoom={11}
          onGoogleApiLoaded={({map, maps}) => this.apiIsLoaded(map, maps, boundingPoints, points, rides)}
        >
        {
          points.map((point) => {
            return <MapMarker key={point.key}
              className="border border-secondary rounded-circle d-inline p-3 bg-white text-success"
              lat={point.lat}
              lng={point.lng}
              title={point.title}
              label={point.label}
              value={point.value}
              formatter={point.formatter}
              onClick={() => onClickPoint(point.key, point.label)}
            />
          })
        }
        {
          rides.reduce((points, ride) => {
            if (ride && ride.start_geo && ride.end_geo) {
              points.push(
                <MapMarker
                  key={ride.ride_id+"_start"}
                  className="marker22 marker-pickup"
                  lat={ride.start_geo.lat}
                  lng={ride.start_geo.lon}
                  title={'Start'}
                  label={ride.start_address}
                />,
                <MapMarker
                  key={ride.ride_id+"_end"}
                  className="marker22 marker-dropoff"
                  lat={ride.end_geo.lat}
                  lng={ride.end_geo.lon}
                  title={'End'}
                  label={ride.end_address}
                />);
            }
            return points;
          }, [])
        }
        </GoogleMapReact>
      </div>
    </Fragment>;
  }
}

const MapMarker = ({title, label, value, formatter, className, onClick}: any) => (
  <button
    style={{position: 'absolute', transform: 'translate(-50%, -50%)'}}
    data-container="body" data-toggle="popover" data-placement="left"
    className={className}
    title={title+'/'+label}
    onClick={onClick}>
    <Format formatter={formatter} value={value} />
  </button>
);
