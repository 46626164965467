import React, {Component} from 'react';
import {startExportJob} from '../services/service';
import {getDownloadUrl} from '../services/firebase';
import {Utils} from "../utils/Utils";

type TProps = {
  params: any,
  close: () => void
}

type TState = {
  errMsg: string,
  downloadUrl: string,
}

export class ExportModal extends Component<TProps, TState> {
  state: TState = {
    errMsg: '',
    downloadUrl: '',
  };

  async componentDidMount(): Promise<void> {
    let url: string|null;
    const exportId = await startExportJob(this.props.params).catch((e) => {
      this.setState({errMsg: 'Exporting Error: ' + e.toString()})
    });
    if (!exportId)
      return;

    console.log('ExportModal exportID: ', exportId);

    while (true) {
      url = await getDownloadUrl(exportId);
      if (url) {
        this.setState({downloadUrl: url});
        break;
      }
      await Utils.sleep(5*1000);
    }
  }

  render() {
    const {
      errMsg,
      downloadUrl
    }: TState = this.state;

    return (
      <div className="modal" tabIndex={-1} role="dialog" style={{display: "block"}}>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Data Export & Download</h5>
            </div>
            <div className="modal-body">
            {
              errMsg ?
                <p>{errMsg}</p>
                :
                <div>
                  <p>{downloadUrl ? 'Exporting Completed' : 'Exporting'}</p>
                  <div className="progress">
                    <div className="progress-bar progress-bar-striped bg-success progress-bar-animated"
                      role="progressbar" style={{width: "100%"}}
                      aria-valuenow={100} aria-valuemin={0} aria-valuemax={100}>
                    </div>
                  </div>
                </div>
            }
            </div>
            {
              downloadUrl &&
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary"
                    onClick={this.props.close}>Close</button>
                  <a href={downloadUrl}
                     className="btn btn-primary"
                     target='_blank' rel="noopener noreferrer"
                  >
                    Download
                  </a>
                </div>
            }
          </div>
        </div>
      </div>
    );
  }
}
