import React from 'react';
import {Route, NavLink, Switch, Router, Redirect} from 'react-router-dom';
import {createBrowserHistory} from 'history';

import {signOutFirebase} from '../services/firebase';
import {verifyUser} from '../services/zumApi';
import {LoginPage} from './loginPage';
import {Search} from './Search';
import {Metrics} from './Metrics';
import {MapView} from './MapView';
import '../App.scss';

const history = createBrowserHistory();

export class App extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      init: false,
      loggedIn: false,
      location: {},
    };
    history.listen((location) => {
      this.setState({location});
    });
  }

  componentDidMount() {
    verifyUser()
      .then(() => {
        this.login(true);
      })
      .catch(() => {})
      .finally(() => {
        this.setState({init: true});
      });
  }

  login = (act: boolean): void => {
    this.setState({loggedIn: act});
  };

  onClickLogout = async () => {
    await signOutFirebase();
    this.login(false);
  };

  render() {
    // wait for the page to try to get user
    if (!this.state.init)
      return null;

    if (!this.state.loggedIn) {
      return <LoginPage login={this.login} />;
    }

    return <Router history={history}>
      <div>
        <nav className="navbar navbar-expand-lg navbar-light bg-white pl-5">
          <a className="navbar-brand" href="/">
            <img src="https://ridezum.com/img/logo.svg" height="30" alt=""/>
          </a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"/>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <NavLink to={{
                  pathname: '/search',
                  search: this.state.location.search,
                }} className="nav-link" activeClassName="active">List</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to={{
                  pathname: '/metrics',
                  search: this.state.location.search,
                }} className="nav-link" activeClassName="active">Metrics</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to={{
                  pathname: '/map',
                  search: this.state.location.search,
                }} className="nav-link" activeClassName="active">Map</NavLink>
              </li>
            </ul>
            <div className="float-right p-2 text-muted">
              Powered by Apollo
              <img className="apollo-img align-top ml-1" width="24px" height="24px"
                alt="Apollo" src="/apollo.png"
                onClick={this.onClickLogout} />
            </div>
          </div>
        </nav>
        <Switch>
          <Route path="/search" component={Search}/>
          <Route path="/metrics" component={Metrics}/>
          <Route path="/map" component={MapView}/>
          <Redirect from="/" to="/search" />
        </Switch>
      </div>
    </Router>
  }
}
