import React, {Component} from 'react';

import {signInFirebase} from '../services/firebase';
import {verifyUser} from '../services/zumApi';

export class LoginPage extends Component<any> {
  state = {
    error_message: ''
  };

  onLogin = async (event: any) => {
    let email = event.target.email.value;
    let password = event.target.password.value;

    event.preventDefault();

    try {
      await signInFirebase(email, password);
      await verifyUser();
      this.props.login(true);
    } catch (e) {
      this.setState({error_message: e.message});
    }
  };

  render() {
    return <div className="container">
      <div className="row">
        <div className="col-md-4 offset-4">
          <form className="form-signin" onSubmit={this.onLogin}>
            <div className="text-center mb-4 mt-5">
              <a className="navbar-brand" href="/">
                <img src="https://ridezum.com/img/logo.svg" height="80" alt=""/>
              </a>
              <h1 className="h3 mb-3 font-weight-normal mt-3">Search & Analytics</h1>
              <p>Powered by Apollo
                <img className="apollo-img align-top ml-1" width="24px" height="24px"
                  alt="Apollo" src="/apollo.png" />
              </p>
              {
                !this.state.error_message ? null :
                <div className="alert alert-danger" role="alert">
                  {this.state.error_message}
                </div>
              }
            </div>

            <div className="form-label-group mb-3">
              <input type="email" name="email" id="inputEmail" className="form-control" placeholder="Email address"
                     required autoFocus/>
              <label htmlFor="inputEmail">Email address</label>
            </div>

            <div className="form-label-group mb-3">
              <input type="password" name="password" id="inputPassword" className="form-control" placeholder="Password"
                     required/>
              <label htmlFor="inputPassword">Password</label>
            </div>

            <button className="btn btn-lg btn-primary btn-block" type="submit">Sign in</button>
            <p className="mt-5 mb-3 text-muted text-center">&copy; Zum Services</p>
          </form>
        </div>
      </div>
    </div>;
  }
}
