import React, {Fragment} from 'react';

type Props = {
  value: number,
  formatter?: string
};

export const NumToInt = ({value}: {value: number|string}) => {
  let intVal = parseInt(value as string);
  if (isNaN(intVal)) {
    intVal = 0;
  }
  return (
    <Fragment>
      {intVal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
    </Fragment>
  );
};

export const CentToDollar = ({value}: {value: number|string}) => {
  let cent = parseFloat(value as string), dollar: string;

  if (isNaN(cent)) {
    cent = 0;
  }
  dollar = (cent/100).toFixed(2);
  return (
    <Fragment>
      {
        dollar.replace(/\d(?=(\d{3})+\.)/g, '$&,')
          .replace(/^([-]?)/, '$1$')
      }
    </Fragment>
  );
};

const Percentage = ({value}: {value: number|string}) => {
  let real: number = parseFloat(value as string);

  if (!isFinite(real)) {
    return (
      <Fragment>
        {'N/A'}
      </Fragment>
    );
  }

  return (
    <Fragment>
      {(real*100).toFixed(2) + '%'}
    </Fragment>
  );
};

export const Format = ({value, formatter}: Props) => {
  switch (formatter) {
    case 'integer':
      return <NumToInt value={value} />;
    case 'cent_to_dollar':
      return <CentToDollar value={value} />;
    case 'percentage':
      return <Percentage value={value} />;
    default:
      return <Fragment>{value}</Fragment>;
  }
};
