import React, {Fragment} from 'react';
import _ from 'lodash';
import ngeohash from 'ngeohash';

import {Map, TMapPoint} from '../components/Map';
import {Aggregation} from '../components/Aggregation';
import {Paginator} from '../components/Paginator';
import {Ride} from '../components/Ride';
import {Format} from '../components/Format';
import {ActiveFiltersBar, TActiveFilter} from '../components/ActiveFiltersBar';
import {CommonWidgets} from "./CommonWidgets";

import * as Service from '../services/service';
import {BaseComponent, TState} from "./BaseComponent";
import {TCondition} from "../utils/QueryCondition";

export class MapView extends BaseComponent {
  state: TState = {
    // data states
    total: 0,
    size: 30,
    es_time: {},
    geoBoundingPoints: [],
    summary: [],
    results: [],
    aggs: {},
    excluded_aggs: {},
    ext_filters: {},
    units: {},

    // UI states
    conditionChanged: false,
    conditionObj: this.queryCondition.getConditionObject(),
    loading: false,
    error: '',
  };

  protected generateRemovingConditionNames = (): string[] => {
    return ['size', 'from', 'sort'];
  };

  protected fetchData = async (): Promise<void> => {
    this.setState(() => ({loading: true}));
    try {
      const {
        total,
        size,
        es_time,
        geoBoundingPoints,
        summary,
        results,
        aggs,
        excluded_aggs,
        ext_filters,
        units
      } = await Service.getRidesWithQs(this.queryCondition.toQueryParams());

      this.setState({
        total,
        size,
        es_time,
        geoBoundingPoints,
        summary,
        results,
        aggs,
        excluded_aggs,
        ext_filters,
        units,
        error: ''
      }, this.postFetchData);
    } catch (e) {
      console.log(e);
      this.setError(e.name+': '+e.message);
    } finally {
      this.setState({loading: false});
    }
  };

  public render() {
    const {
      // data states
      total,
      size,
      es_time,
      geoBoundingPoints,
      summary,
      results,
      aggs,
      excluded_aggs,
      ext_filters,
      units,

      // UI states
      conditionChanged,
      conditionObj,
      error,
    } = this.state;
    const activeFilters: {[name: string]: TActiveFilter} = {};
    let aggsArray: any[], excludedAggsArray: any[], points: TMapPoint[] = [];
    let mapPointAggName: string;

    // ActiveFilters: process Active filters for display
    Object.keys(conditionObj!)
      .filter((name: string) => !['size', 'from', 'sort', 'unit', 'aggs'].includes(name))
      .forEach((name: any) => {
        let title: string;

        if (aggs[name]) {
          title = aggs[name].config.title;
        } else if (ext_filters[name]) {
          title = ext_filters[name].title;
        } else {
          title = name;
        }
        activeFilters[name] = {
          title: title,
          label: conditionObj![name].map((cond: TCondition) => cond.label).join(', ')
        };
      });

    // Aggregations: transform aggregations to array sorted by order
    [aggsArray, excludedAggsArray] = [aggs, excluded_aggs].map((aggObj: any) => {
      return Object.keys(aggObj || {}).map((aggName: any) => ({
        aggName,
        ...aggObj[aggName]
      }));
    });
    aggsArray = _.orderBy(aggsArray, 'config.order');
    excludedAggsArray = _.orderBy(excludedAggsArray, 'config.order');

    // Map: extract map marker points from specific aggregation result
    if (aggs['geo_spot'] || aggs['geo_box']) {
      mapPointAggName = aggs['geo_spot'] ? 'geo_spot' : 'geo_box';
      points = aggs[mapPointAggName].buckets.map((bucket: any): TMapPoint => {
        const {latitude, longitude} = ngeohash.decode(bucket.key);
        return {
          key: bucket.key,
          lat: latitude,
          lng: longitude,
          title: aggs[mapPointAggName].config.title,
          label: bucket.label,
          value: bucket.value,
          formatter: aggs[mapPointAggName].config.formatter
        }
      });
    } else if (aggs['zone'] || aggs['region']) {
      mapPointAggName = aggs['zone'] ? 'zone' : 'region';
      points = aggs[mapPointAggName].buckets.map((bucket: any): TMapPoint => {
        return {
          key: bucket.key,
          lat: bucket.centroid.location.lat,
          lng: bucket.centroid.location.lon,
          title: aggs[mapPointAggName].config.title,
          label: bucket.label,
          value: bucket.value,
          formatter: aggs[mapPointAggName].config.formatter
        }
      });
    }
    points = _.orderBy(points, 'value');

    return <div className="App container-fluid pt-3">
      {/* Active filters bar */}
      <ActiveFiltersBar
        activeFilters={activeFilters}
        removeFilter={this.applyRemovingCondition}
      />

      <div className="row">
        {/* Summary & Aggregations */}
        <div className="col-md-3">
          {/* summary */}
          <div className="card mb-3">
            <div className="card-header">
              <dl className="row my-0">
              {
                summary!.map((item: any) => (
                  <Fragment key={item.title}>
                    <dt className="col-md-6">{item.title + ': '}</dt>
                    <dd className="text-right col-6 mb-0">
                      <Format value={item.value} formatter={item.formatter}/>
                    </dd>
                  </Fragment>
                ))
              }
              </dl>
            </div>
          </div>
          {
            /* aggregations */
            [aggsArray, excludedAggsArray].map((aa: any[]) => {
              return aa.map((agg: any) => {
                // hide aggregation on boolean field whose filter is already applied
                if (activeFilters[agg.aggName] && agg.config.bool)
                  return null;
                if (agg.config.hidden)
                  return null;
                return (
                  <Aggregation
                    key={agg.aggName}
                    name={agg.aggName}
                    title={agg.config.title}
                    type={agg.config.aggType}
                    formatter={agg.config.formatter}
                    isOptional={agg.config.optional}
                    isBool={agg.config.bool}
                    queryCondition={this.queryCondition}
                    data={agg}
                    addCondition={this.addCondition}
                    removeCondition={this.removeCondition}
                    applySettingCondition={this.applySettingCondition}
                  />
                );
              });
            })
          }
        </div>

        <div className="col-md-9">
          {/* Map */}
          <Map
            boundingPoints={geoBoundingPoints!}
            points={points}
            rides={total! < 200 ? results! : []}
            onClickPoint={(value: string, label: string) => this.applySettingCondition(mapPointAggName, value, label)}
            applyFilter={this.applySettingExclusiveCondition}
          />

          {/* CommonWidget */}
          <CommonWidgets
            units={units}
            excludedAggsArray={excludedAggsArray}
            queryCondition={this.queryCondition}
            applyAddingFilter={this.applyAddingConditions}
            applyRemovingFilter={this.applyRemovingCondition}
            applyFilter={this.applySettingCondition}
            applyExclusiveFilter={this.applySettingExclusiveCondition}
          />

          {/* rides results */}
          {
            this.state.loading ?
              <div className="spinner-border m-1" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              :
              <div className="my-2">
                {es_time.title + ': '}
                <Format value={es_time.value} formatter={es_time.formatter}/>
              </div>
          }

          {
            error &&
            <div className="alert alert-danger">
              {error}
            </div>
          }

          {
            <Fragment>
            {
              (!results || !results.length) ? null :
                <Fragment>
                  <Fragment>
                    <Paginator
                      total={total!}
                      pageSize={size!}
                      from={this.queryCondition.getSingleConditionValue('from') || '0'}
                      setFrom={(from: string): void => this.applySettingCondition('from', from)}
                    />
                  </Fragment>
                  <Fragment>
                    {
                      results.map((result: any) =>
                        <Ride
                          key={result.ride_id}
                          rideDoc={result}
                        />
                      )
                    }
                  </Fragment>
                </Fragment>
            }
            </Fragment>
          }
          <Paginator
            total={total!}
            pageSize={size!}
            from={this.queryCondition.getSingleConditionValue('from') || '0'}
            setFrom={(from: string): void => this.applySettingCondition('from', from)}
          />
        </div>
      </div>
      {
        conditionChanged &&
        <input type='button' className={'fixed-bottom btn-primary btn-block'} value={'Apply'}
          onClick={this.search} />
      }
    </div>;
  }
}
